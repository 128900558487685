<template>
  <router-link :to='isAdmin ? "/artists-list" : "/profil"'>
    <div id="brand" v-if='!$props.isCenter'/>
    <div v-if="!store.state.isMobile && store.state.title == null">
      <p :class='$props.isCenter? "brand-large" : "brand-small" '>artspedia<br/>studio</p>
    </div>
    <p v-if="store.state.title != null" id="title">{{ store.state.title }}</p>
  </router-link>
</template>

<script lang="ts">
import * as Vue from 'vue';
import store from "@/store/index";

export default Vue.defineComponent({
  data() {
    return {
      store,
    }
  },
  props: ['isCenter'],
  computed: {
    isAdmin() {
      return store.state.isAdmin
    }
  }
})
</script>

<style lang="sass" scoped>
p
  font-family: $font-brand
  color: rgb(33, 33, 33)

p.brand-small
  font-size: 20px
  line-height: 20px
  margin-top: -5px
  letter-spacing: 0.5px

p.brand-large
  font-size: 36px
  line-height: 34px
  text-align: center
  letter-spacing: 0.5px
  margin-bottom: 15px

#title
  font-family: $font-main

#brand
  @include square(_(64px))
  background-image: url('@/assets/img/brand.png')
  background-size: contain
  background-repeat: no-repeat
  margin-top: 2px
  margin-bottom: -2px

a
  @include row(_(20px))
</style>