<template>
  <nav v-click-outside="hide" :class="{ mobile: store.state.isMobile }">
    <div id="nav-container">
      <Brand :is-center="false"/>
      <button id="hamburger" v-if="store.state.isMobile && store.state.isAuthenticated" @click="toggle"
              :class="{ open: opened }">
        <Menu font-size="32px"/>
      </button>
      <div
          v-if="!['login','register','verify-email', 'forget-password'].includes($route.name) && !store.state.isMobile"
          id="link-container" v-on:click="hide">
        <nav-tab v-if="isAdmin" to="/artists-list">Artists List</nav-tab>
        <nav-tab v-if="isAdmin" to="/artists-registration">Artists Registration</nav-tab>
        <nav-tab v-if="!isAdmin" to="/profil">Your Profile</nav-tab>
        <nav-tab v-if="!isAdmin" to="/portfolio">Portfolio</nav-tab>
        <nav-tab v-if="!isAdmin" to="/service">Service</nav-tab>
        <nav-tab v-if="!isAdmin" to="/schedule">Schedule</nav-tab>
        <!-- TODO: -->
        <!-- <button v-if="!isAdmin" id="profile-button">
          View profile page
        </button> -->
        <button id="profile-button" v-if="store.state.isMobile">
          <div class="photo"
               :style="{'background-image': 'url('+ (store.state.photoUrl || '@/assets/img/brand.png') +')'}"/>
          <div>
            <p>{{ store.state.email }}</p>
            <p id="small">{{ isAdmin ? "ADMIN" : "ARTIST" }}</p>
          </div>
        </button>
        <nav-tab @test-click="logout" to="/" v-if="store.state.isMobile">Log out</nav-tab>
      </div>
      <router-link v-if="'login'.includes($route.name) && !store.state.isMobile"  to="/register">
        <n-button
            id="registerBtn"
            type="primary"
            size="medium"
        >
          <strong>Register</strong>
        </n-button>
      </router-link>
      <router-link v-if="'register'.includes($route.name) && !store.state.isMobile"  to="/login">
        <n-button
            id="loginBtn"
            type="primary"
            size="medium"
        >
          <strong>Login</strong>
        </n-button>
      </router-link>
      <Dashboard v-if="!['login','register'].includes($route.name)"/>
    </div>
    <div v-if="!['login','register'].includes($route.name) && store.state.isMobile" id="link-container"
         :class="{ mobile: store.state.isMobile, open: opened }" v-on:click="hide">
      <div id="link-gap"/>
      <nav-tab v-if="isAdmin" to="/artists-list">Artists List</nav-tab>
      <nav-tab v-if="isAdmin" to="/artists-registration">Artists Registration</nav-tab>
      <nav-tab v-if="!isAdmin" to="/profil">Your Profile</nav-tab>
      <nav-tab v-if="!isAdmin" to="/portfolio">Portfolio</nav-tab>
      <nav-tab v-if="!isAdmin" to="/service">Service</nav-tab>
      <nav-tab v-if="!isAdmin" to="/schedule">Schedule</nav-tab>
      <button id="profile-button" v-if="store.state.isMobile">
        <div class="photo"
             :style="{'background-image': 'url('+ (store.state.photoUrl || '@/assets/img/brand.png') +')'}"/>
        <div>
          <p>{{ store.state.email }}</p>
          <p id="small">{{ isAdmin ? "ADMIN" : "ARTIST" }}</p>
        </div>
      </button>
      <nav-tab @test-click="logout" to="/" v-if="store.state.isMobile">Log out</nav-tab>
    </div>
  </nav>
</template>

<script lang="ts">
import * as Vue from "vue";
import Brand from "@/components/Brand.vue";
import Dashboard from "@/components/Dashboard.vue";
import NavTab from "@/components/NavTab.vue";
import vClickOutside from 'click-outside-vue3';
import { NButton} from 'naive-ui';
import {Menu} from "mdue";
import store from "@/store/index";


export default Vue.defineComponent({
  components: {
    Brand,
    Dashboard,
    Menu,
    NavTab,
    NButton
  },
  data() {
    return {
      opened: false,
      isLoggedIn: store.state.isAuthenticated,
      store
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    hide() {
      this.opened = false
    },
    logout() {
      this.store.commit('removeToken')
      this.store.commit('removeRefresh')
      this.$router.push({name: 'login'})
    },
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    isAdmin() {
      return store.state.isAdmin
    }
  }
});
</script>

<style lang="sass" scoped>
nav
  z-index: 1000
  height: fit-content
  width: 100%
  box-sizing: border-box
  flex-grow: 0
  flex-shrink: 0

  &.mobile
    height: fit-content
    overflow: hidden

#nav-container
  @include fit
  box-sizing: border-box
  background-color: $clr-white
  height: fit-content
  justify-content: space-between
  @include pad(_(15px))
  @include shade
  @include row(20px)

#hamburger
  padding: 5px
  color: $clr-brand

  &.open
    color: $clr-white
    background-color: $clr-brand

#link-container
  @include row(0px)
  flex-grow: 1
  align-items: flex-end
  overflow: hidden
  transform-origin: top
  transition: 0.2s

  &.mobile
    position: absolute
    height: fit-content
    @include col(0px)
    transform: scaleY(0)
    width: 100vw
    @include shade(10px)
    border-radius: 0px 0px 12px 12px

    #link-gap
      order: 1
      @include fit
      height: _(16px)
      background-color: $clr-white

    &.open
      transform: scaleY(1)

#link-gap
  width: _(64px)+2*_(20px)

#profile-button
  background: $clr-white
  text-align: left
  border-radius: 0
  color: $clr-dark
  @include row
  @include fit
  @include pad(_(15px))

  &:active
    color: $clr-white
    background: $clr-medium

#registerBtn, #loginBtn
  font-size: 14px
  margin-right: 3px
</style>